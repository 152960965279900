@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
.container {
  min-height: 100vh;
  margin: auto;
  display: contents;
  @media (min-width: 768px) and (max-width: 979px) {
    min-width: 780px;
  }
  @media (min-width: 980px) {
    min-width: 800px;
  }
  @media (min-width: 1200px) {
    min-width: 1024px;
  }
  .error_main_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 72px);
    .error_main_wrapper_inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      .content {
        > div {
          text-align: center;
          color: $gray300;
          font-weight: $fw400;
          &.title {
            margin-top: 40px;
            font-size: $f32;
            line-height: $lh40;
          }
          &.text {
            font-size: $f16;
            line-height: $lh20;
            margin-top: 16px;
          }
        }
      }
      .button_box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 320px;
        margin-top: 30px;
        border-radius: 2px;
        background-color: $blue500;
        > button {
          width: 100%;

          > span {
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }
  }
}
